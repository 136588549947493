import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';
import { BiClipboard } from 'react-icons/bi';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.jpg';
import frame1 from './assets/theme_asset/frame1.jpg';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';
import './game.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function Deposite() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [dividedAmount, setDividedAmount] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [modalMessage1, setModalMessage1] = useState({});
  const [fileValid, setFileValid] = useState(true);
  const [id, setId] = useState(''); // Define and initialize the id state
  const [receipt, setReceipt] = useState(''); // Define and initialize the receipt state
  const [tpin, setTpin] = useState('');
  const [copied, setCopied] = useState(false);
  const [amount_type , setamount_type] = useState('');
  const [network_type , setnetwork_type] = useState('');
  const [network_type_input , setnetwork_type_input] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
     
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'receipt' && files.length > 0) {
      const selectedFile = files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  
      if (selectedFile && allowedTypes.includes(selectedFile.type)) {
        setFileValid(true);
        // Set the file object itself in state
        setReceipt(selectedFile);
      } else {
        setFileValid(false);
        e.target.value = null;
        return;
      }
    }

    // Update state based on input name
    if (name === 'amount') setAmount(value);
    else if (name === 'tpin') setTpin(value);
    else if (name === 'dividedamount') setDividedAmount(value);
    else if (name === 'amount_type') setamount_type(value);
    else if (name === 'network_type') setnetwork_type(value);

  };
  const handleNetworkTypeChange = (event) => {
    setnetwork_type_input(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)
    try {

     
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);
      formData.append('tpin', tpin);
      formData.append('amount_type', amount_type);
      formData.append('network_type', network_type);
      formData.append('network_type_input', network_type_input);
      formData.append('dividedamount', dividedAmount);
      // Append receipt file to FormData
      if (receipt) {
        formData.append('receipt', receipt);
      }
      for (let [key, value] of formData.entries()) {
      }
      
      if (dividedAmount < 10) {
        // Show error message or handle invalid amount
        setModalMessage({
          title: 'Invaild Balance',
          content: 'Please Enter the amount greater than 10',
        });
        setShowModal(true);
    }else{

    
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/make_deposite`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });
      setModalMessage1({
        title: 'Deposte Request send Successfully',
        content: 'You will receive your deposite amount with in 1 hour successfully',
      });
      setShowModal1(true);
      navigate('/deposite');
      fetchData(); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
      setIsSubmitting(false)}
    } catch (error) {
      setIsSubmitting(false)

      console.error('Error updating user:', error);
      let errorMessage = 'An error occurred';
      if (error.response && error.response.data) {
        errorMessage = error.response.data; // This should give you ["Insufficient Balance"]
      }
      setModalMessage({
        title: 'Deposite Request Failed',
        content: `${errorMessage}`,
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  const closeModal1 = () => {
    setShowModal1(false);
//     const userId = data.user.email; // Replace with actual user ID
//     const userId1 = data.user.name; // Replace with actual user ID

// // Construct the withdrawal request message with dynamic user ID and amount
// const withdrawRequestMessage = encodeURIComponent(`Hello! www.wazir.co.in 👋 Just wanted to let you know that user ${userId1} with ${userId} has initiated a Deposit request of ₹ ${amount} from their account. Kindly confirm once you've processed it. Thank you!`);

// // Replace placeholders with actual phone number
// const phoneNumber = '7219169907';

// // Construct the WhatsApp URL with the message
// const whatsappURL = `https://wa.me/${phoneNumber}?text=${withdrawRequestMessage}`;

// // Redirect to the WhatsApp chat
// window.location.href = whatsappURL;
   };
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/deposite');
    }
    feather.replace();
  
    fetchData();
  }, []);
  
  const handleButtonClick = (value) => {
    setAmount(value);
  };
  
  useEffect(() => {
    if (amount !== '') {
      const divided = amount / 90;
      setDividedAmount(divided.toFixed(2));
    } else {
      setDividedAmount('');
    }
  }, [amount]);
 
  

  const copyToClipboard = () => {
const textToCopy = 'Q65919506@ybl';

  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
  
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };


  const copyToClipboard1 = () => {
    const textToCopy = data.admin_data.upi_id;
    
      
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              setCopied(true); // Set copied state to true
              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Could not copy text: ', error));
        } else {
          // Fallback for browsers that don't support clipboard API
          const textField = document.createElement('textarea');
          textField.innerText = textToCopy;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand('copy');
          textField.remove();
      
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }
      };
  

  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (
  <body className=''>
    
    <div className=''>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
     {showModal1 && <Modal message={modalMessage1} closeModal={closeModal1} />}
       
     <Sidebar />
  {/* header end */}
  {/* login section start */}
 
  

  <form className="auth-form-1 mt-3" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="custom-container-1">
        <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Amount Type</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="amount_type"
                name="amount_type"
                onChange={handleChange}
                required
                >
                <option value="">Select Amount Type</option>
                {/* <option value="inr">Transfer to INR</option> */}
                <option value="usdt">Transfer to USDT</option>
                </select>

                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> {/* Down arrow icon */}
        </div>
               
              
            </div>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Network Type</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="network_type"
                name="network_type"
                onChange={handleChange}
                required
                >
                <option value="">Select Network Type</option>
                <option value="bnb_smart_chain_BEP20">BNB Smart Chain (BEP20)</option>
                <option value="eos">EOS</option>
                <option value="near_protocol">NEAR Protocol</option>
                <option value="avax_c_chain">AVAX C-Chain</option>
                <option value="polygon">Polygon</option>
                <option value="solana">Solana</option>
                <option value="tezos">Tezos</option>
                <option value="toncoin">Toncoin</option>
                <option value="tron_TRC20">Tron (TRC20)</option>
                <option value="opbnb">opBNB</option>
                <option value="optimism">Optimism</option>
                <option value="arbitrum_one">Arbitrum One</option>
                <option value="asset_hub_polkadot">Asset Hub (Polkadot)</option>
                <option value="bnb_beacon_chain_BEP2">BNB Beacon Chain (BEP2)</option>
                <option value="celo">CELO</option>
                <option value="ethereum_erc20">Ethereum (ERC20)</option>
                <option value="kavaevm">KAVAEVN</option>
                <option value="other">Other...</option>
                </select>

                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> {/* Down arrow icon */}
        </div>
               
              
            </div>
          </div>
          <div className="form-group">
          {/* <label htmlFor="name" className="form-label d-flex" style={{color:'black'}}>
          Amonut in INR  {amount_type === 'usdt' && (<label style={{color:'black'}}> (₹90 is equal to 1 USDT)</label>)}
        </label> */}
            {network_type === 'other' && 
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="network_type_input"
                name="network_type_input"
                placeholder="Enter Network Type"
                onChange={handleNetworkTypeChange}
                value={network_type_input}
              />


{/* <span className="token-text">BBN Token</span> */}
                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                      
                    />
              
            </div>}
          </div>
         
          {/* <div>
            <div className='row m-2'>
                <div className='col-1'></div>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('100')}>100</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('500')}>500</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('1000')}>1000</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('1500')}>1500</button>
            <div className='col-1'></div>
            </div>
            <div className='row m-2'>
            <div className='col-1'></div>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('2000')}>2000</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('4000')}>4000</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('5000')}>5000</button>
            <button type="button" style={{backgroundColor:'#d01c4d',color:'white'}} className='col-2 btn  p-1 m-1' onClick={() => handleButtonClick('10000')}>10000</button>
            <div className='col-1'></div>
            </div>
        </div> */}
        {amount_type === 'usdt' && <div className="form-group">
        <label htmlFor="name" className="form-label" style={{color:'black'}}>
          Amonut in USDT
        </label>
        <input
                type="text"
                className="form-control"
                id="dividedamount"
                name="dividedamount"
                placeholder="Enter Amount"
                defaultValue={dividedAmount}
                onChange={handleChange}
            
              />
              </div> }
              {amount_type === 'usdt' && <div className="alert alert-info mt-2" style={{fontSize:'15px'}} role="alert">
  ⚠️ Only send USDT(BEP20) assets to this address
  Other assets will be lost forever.
</div>
}
     {amount_type === 'usdt' && <div className="form-group">
        <label htmlFor="name" className="form-label">
          Payment Receipt 
        </label>
        <div className="form-input row">
         <center><img  src={`${apiImageUrl}/qr_code/${data.admin_data.qr_img}`} alt="frame" style={{width:'180px'}}/></center>
          <div className='row'> 
            <div className='col-3'>Address:</div>
            <div className='col-8' style={{ color: 'blue', wordBreak: 'break-word' }}>
                {data.admin_data.upi_id}
            </div>
            <div className='col-1'>
                {!copied && (
                    <div className="btn d-flex btn-link" style={{ textDecoration: 'none', color: 'red' }} onClick={copyToClipboard1}>
                        Copy <BiClipboard style={{ marginLeft: '2px', color: 'red' }} />
                    </div>
                )}
                {copied && <span className="text-success">Copied!</span>}
            </div>
          </div>
         {/* <div className="d-flex " style={{justifyContent:'space-between'}}>
            <div style={{display:'flex'}}>Address :<div style={{color:'blue',width:'40%'}}> {data.admin_data.upi_id}</div></div>
            {!copied && (
  <div className="btn d-flex btn-link " style={{textDecoration:'none', color:'red'}} onClick={copyToClipboard1}>
    Copy <BiClipboard style={{ marginLeft: '2px', color: 'red' }} />
  </div>
)}
{copied && <span className="text-success">Copied!</span>}


                </div> */}
        </div>
      </div>}
      {amount_type === 'inr' && <div className="form-group">
        <label htmlFor="name" className="form-label">
          Payment Receipt 
        </label>
        <div className="form-input row">
         <center><img src={frame} alt="frame" style={{width:'180px'}}/></center>
         <div className="d-flex " style={{justifyContent:'space-between'}}>
            <div style={{display:'flex'}}>UPI :<div style={{color:'blue'}}>  Q65919506@ybl </div></div>
            {!copied && (
  <button className="btn d-flex btn-link " style={{textDecoration:'none', color:'red'}} onClick={copyToClipboard}>
    Copy <BiClipboard style={{ marginLeft: '2px', color: 'red' }} />
  </button>
)}
{copied && <span className="text-success">Copied!</span>}


                </div>
        </div>
      </div>}
      <div className="form-group">
            <label htmlFor="receipt" className="form-label">Payment Receipt</label>
            <div className="form-input">
              <input
                type="file"
                className="form-control"
                id="receipt"
                name="receipt"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                required
              />
            </div>
            {!fileValid && <div className="text-danger">Please select a valid image file (JPEG, PNG, JPG)</div>}
          </div>
          <div className="form-group mb-2">
            <label htmlFor="tpin" className="form-label" style={{color:'black'}}></label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="tpin"
                name="tpin"
                placeholder="Enter your Transaction Ref"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}  disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>
          <div className='mt-2' style={{textAlign:'center' , fontSize:'15px',fontWeight:'bold'}}>Need Support:<br/><Link to="/about">Click Here</Link></div>
   
        </div>
        
      </form>
  
       <Footer />
        
      </div>
      </body>
  );
}

 


export default Deposite;
 