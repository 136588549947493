import { Children } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";

import './modal.css';

const MyModal = ({ closeModal, children, handleCloseButton }) => {
    useEffect(() => {
      document.body.style.overflowY = "hidden";
      return () => {
        document.body.style.overflowY = "scroll";
      };
    }, []);
  
    return ReactDOM.createPortal(
      <>
        <div className="modal-wrapper" ></div>
        <div className="modal-container">
          {children} {/* Render the children passed to MyModal */}
          {handleCloseButton}
        </div>
      </>,
      document.querySelector(".myPortalModalDiv") || document.body
    );
  };
  
  
  

export default MyModal;