import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './SkeletonLoader.css'; // Import your CSS file for additional styling
import 'react-loading-skeleton/dist/skeleton.css';
import Footer from './footer';
import Sidebar from './Sidebar';

import './App.css';
import './game.css';

const SkeletonLoader = ({ showHeader, showFooter }) => {
  return (
    <body className="">
      <div className="fixed-header-footer-container">
        {/* Conditional rendering of header */}
        {showHeader && (
          <div className="fixed-header">
            <Sidebar />
          </div>
        )}


          <div className="loader-section">
          <span className="loader"></span>
          </div>


      

        {/* Conditional rendering of footer */}
        {showFooter && (
          <div className="fixed-footer">
            <Footer />
          </div>
        )}
      </div>
    </body>
  );
}

export default SkeletonLoader;
