import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';

import authentication1 from '../assets/theme_asset/authentication/1.svg';

import google from '../assets/theme_asset/svg/google.svg';

import facebook from '../assets/theme_asset/svg/facebook.svg';



function Signin() {

  useEffect(() => {
    // Replace icons after component is mounted
    feather.replace();
  }, []);

  

          
  return (
    <div>
      <div className="auth-header">
          <a href="index-2.html"> <i className="back-btn" data-feather="arrow-left" /> </a>
          <img className="img-fluid img" src={authentication1} alt="v1" />
          <div className="auth-content">
            <div>
              <h2>Welcome back !!</h2>
              <h4 className="p-0">Fill up the form</h4>
            </div>
          </div>
        </div>
        {/* header end */}
        {/* login section start */}
        <form className="auth-form" target="_blank">
          <div className="custom-container">
            <div className="form-group">
              <label htmlFor="inputusername" className="form-label">Email id</label>
              <div className="form-input">
                <input type="email" className="form-control" id="inputusername" placeholder="Enter Your Email" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputpin" className="form-label">Pin</label>
              <div className="form-input">
                <input type="password" className="form-control" id="inputpin" placeholder="Enter Your pin" />
              </div>
            </div>
            <div className="remember-option mt-3">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">Remember me</label>
              </div>
              <a className="forgot" href="forgot-password.html">Forgot Pin?</a>
            </div>
            <a href="landing.html" className="btn theme-btn w-100">Sign In</a>
            <h4 className="signup">Don’t have an account ?<a href="signup.html"> Sign up</a></h4>
            <div className="division">
              <span>OR</span>
            </div>
            <a href="https://www.google.co.in/" target="_blank" className="btn gray-btn mt-3"> <img className="img-fluid google" src={google} alt="google" /> Continue with Google</a>
            <a href="https://www.facebook.com/login/" target="_blank" className="btn gray-btn mt-3"> <img className="img-fluid google" src={facebook} alt="google" /> Continue with facebook</a>
          </div>
        </form>
    </div>
  );
}

export default Signin;
