
import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ellipse from './assets/theme_asset/svg/ellipse.svg';


import './App.css';
import './game.css';

import { useEffect } from 'react';

import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import deposite1 from './assets/theme_asset/deposit.png'
import reward from './assets/theme_asset/reward.png'

import ReactPaginate from 'react-paginate';
import Pagination from './react-pegination';
import Modal from './modal';



function Revenue() {

  const navigate = useNavigate();



  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [amount , setAmount] = useState('');
  const [transfer , setTransfer] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);

  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
      const totalAmount1 = response.data.wallet.totalself;
      setTotalAmount1(totalAmount1);
     
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'amount') setAmount(value);
    if (name === 'transfer') setTransfer(value);
  };
  
  const handleSubmit = async (e,totalAmount1) => {
    e.preventDefault();
    try {
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('transfer', transfer);
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);
      // Append receipt file to FormData
      
     
      
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/RevenueWithdraw`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });

      
        
      setModalMessage({
        title: 'Amount Transfered Successfully',
        content: 'Now You Can Play With The Bonus Amount',
      });
      setShowModal(true);
      navigate('/revenue'); 
      fetchData();
    } catch (error) {
      console.error('Error updating user:', error);
      if (totalAmount1 < amount) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To withdraw bouns,Please check the available balance',
        });
        setShowModal(true);
        return;
      }
      setModalMessage({
        title: 'Error',
        content: 'Some thing went wrong. Please insert all details or try again later',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const [daysLeft, setDaysLeft] = useState(0);

useEffect(() => {
  const createdDate = data && data.deposite_report1 && data.deposite_report1.created_at;
  if (!createdDate) return; // Exit early if createdDate is not available

  const depositDate = new Date(createdDate);
  const targetDate = new Date(depositDate.getTime() + (200 * 24 * 60 * 60 * 1000)); // Adding 200 days
  const interval = setInterval(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time component
    targetDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time component

    const differenceInTime = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    setDaysLeft(differenceInDays >= 0 ? differenceInDays : 0);
  }, 1000);

  return () => clearInterval(interval);
}, [data]);



  useEffect(() => {
    // Replace icons after component is mounted
    

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/revenue');
    }
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={true} showFooter={true} /> 

       )
    }

   
   
  
 
    return (
      <body className='' style={{overflow:'hidden'}}>
      <div className=''>
          
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       <Sidebar/>

       {/* <p>Days Left: {daysLeft}</p> */}
       <section style={{marginTop:'35px'}}>
     <center><h2 style={{color:'black'}} >Revenue Wallet</h2><br/><h3 style={{marginTop:'-20px'}}>Day Remains: {daysLeft}/200</h3> </center>
      <div className="row">
        <div className='col-6'>
        <center>
        <div>
          
        </div>
        <div className='card-1'>
          First Deposit <br/>
          $ {data.wallet.first_deposit} 
        </div></center></div>
        <div className='col-6'><center>
        <div>
          
        </div>
        <div className='card-1'>
          Daily Bonus <br/>
          $ {data.wallet.availabe_revenue} 
        </div></center></div></div>
      </section>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form-1" onSubmit={(e) => handleSubmit(e, totalAmount1)} encType="multipart/form-data">
        <div className="custom-container">
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label">Amount</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Where to Transfer</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="transfer"
                name="transfer"
                onChange={handleChange}
                required
                >
                <option value="">Select where to Transfer</option>
                <option value="withdraw">Transfer to Withdraw</option>
                <option value="playWallet">Transfer to Play Wallet</option>
                </select>

                
                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> {/* Down arrow icon */}
        </div>
               
              
            </div>
          </div>
          
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>{transfer === '' && 'Transfer'}{transfer === 'withdraw' && 'Transfer to Withdraw Wallet'}{transfer === 'playWallet' && 'Transfer to Play Wallet' }</button>
        </div>
      </form>
      
  
<div className="custom-container">
        <div style={{color:'white'}}><center><h2>Reports</h2></center></div>
        </div>
        {data.revenue_report.data && data.revenue_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.revenue_report.data.map((revenue, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={deposite1} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{revenue.reason === 'roi' ? 'Daily Bonus' : revenue.reason === 'revenue_transafer_withdraw' ? 'Revenue Transfer to Withdraw Wallet' : revenue.reason === 'revenue_transafer_play' ? 'Revenue Transfer to Play Wallet' : revenue.reason }</h5>
                  <h3 className={revenue.reason === 'roi' ? 'success-color' : 'error-color' }>
                  $ {revenue.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(revenue.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(revenue.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={revenue.reason === 'roi' ? 'success-color' : 'error-color'}>
                {revenue.status}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.revenue_report.links && (
        <Pagination
          pageCount={data.revenue_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}

<section>

  
</section>


<section className="panel-space" />

      
        <Footer/>

</div>

</body>
  
  );
  }

  export default Revenue;
