import logo from './assets/theme_asset/logo1.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';
import facebook from './assets/theme_asset/svg/facebook.svg';
import google from './assets/theme_asset/svg/google.svg';


import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';
import auth1 from './assets/theme_asset/authentication/1.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";







function Login() {

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    email: '',
    password: '',
   
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    // If the input is a file input, set the file to the image field
    setFormData({ ...formData, [name]: value });
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem('modalMessage4', JSON.stringify({
        title: 'Login successfully',
        content: 'You have loggedin successfully',
      }));
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      const response = await axios.post(`${apiBaseUrl}/login`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for file upload
        },
      });
      const token = response.data.token;
      const id = response.data.user_id;
      const loggedInOrRegistered = response.data.token;
    // Store the token in localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('loggedInOrRegistered', loggedInOrRegistered);

    localStorage.setItem('id', id)
      navigate('/'); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error('Error Loggedin user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Failed to Login. Please enter vaild email and Password.',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };

  const handleCheckboxChange = (e) => {
    e.preventDefault(); // Prevent default behavior of checkbox
    // Handle your checkbox logic here (e.g., set a rememberMe state)
  };


  const closeModal = () => {
    setShowModal(false);
  };
  const [modalMessage3, setModalMessage1] = useState(null);

  useEffect(() => {
    // Retrieve modal message from local storage
    const storedMessage = localStorage.getItem('modalMessage3');
    if (storedMessage) {
      setModalMessage1(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem('modalMessage3');
    }
  }, []);

  


  return (
    <body className="">
    <div>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
      {modalMessage3 && (
        <Modal message={modalMessage3} closeModal={() => setModalMessage1(null)} />
      )}
       
       <div className="auth-header">
       <div className="custom-container">
       <div className='row'> 
          <div className='col-2'>
        </div>
        <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'85px', height:'auto', margin:'-20px 0px',}}/></div>
    </div></div>
   
    <div className="auth-content">
      <div>
        <h2 style={{marginTop:'20px'}}>Welcome to Stake Bet!!</h2>
        <h4 className="p-0">Sign in/Sign up</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form" onSubmit={handleSubmit}>
    <div className="custom-container">
      <div className="form-group">
        <label htmlFor="inputusername" className="form-label">
          Email id
        </label>
        <div className="form-input">
          <input
            type="email"
            className="form-control"
            id="email"
            name = "email"
            placeholder="Enter Your Email"
            onChange={handleChange}          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="inputpin" className="form-label">
        Password
        </label>
        <div className="form-input">
          <input
            type="password"
            className="form-control"
            id="password"
            name = "password"
            placeholder="Enter Your pin"
            onChange={handleChange}          />
        </div>
      </div>
      <div className="remember-option mt-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" onChange={handleCheckboxChange}>
            Remember me
          </label>
        </div>
        <a className="forgot" href="/forget_password">
          Forgot Password?
        </a>
      </div>
      <button type='submit' className="btn theme-btn w-100" style={{color:'white'}}> 
        Sign In
      </button>
      <h4 className="signup">
        Don’t have an account ?<Link to="/register"> Sign up</Link>
      </h4>
      {/* <div className="division">
        <span>OR</span>
      </div>
      <a
        href="https://www.google.co.in/"
        target="_blank"
        className="btn gray-btn mt-3"
      >
        {" "}
        <img
          className="img-fluid google"
          src={google}
          alt="google"
        />{" "}
        Continue with Google
      </a>
      <a
        href="https://www.facebook.com/login/"
        target="_blank"
        className="btn gray-btn mt-3"
      >
        {" "}
        <img
          className="img-fluid google"
          src={facebook}
          alt="google"
        />{" "}
        Continue with facebook
      </a> */}
    </div>
  </form>
        
       
        
      </div>
      </body>
  );
}

export default Login;
