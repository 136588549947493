import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';

import rocket  from '../assets/theme_asset/svg/rocket.svg';

import splash  from '../assets/theme_asset/svg/flash.svg';
import logo  from '../assets/theme_asset/logo/logo-white.png';

import lines_fill  from '../assets/theme_asset/logo/logo-white.png';
import card1  from '../assets/theme_asset/card/card5.png';

import card2  from '../assets/theme_asset/card/card4.png';
import card3  from '../assets/theme_asset/card/card3.png';
import card4 from '../assets/theme_asset/card/card2.png';
import card5  from '../assets/theme_asset/card/card1.png';



function Splash() {

  useEffect(() => {
    // Replace icons after component is mounted
    feather.replace();
  }, []);

  

          
  return (
    <div>
     <div className="onboarding-loader" id="onboardingLoader">
          <div className="rocket-img">
            <img className="rocket" data-aos="fade-up" data-aos-duration={1000} data-aos-delay={500} src={rocket} alt="rocket" />
          </div>
          <div className="flash-img">
            <img className="flash" data-aos="fade-up" data-aos-duration={1000} data-aos-delay={800} src={splash} alt="flash" />
          </div>
          <div className="logo-img">
            <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={1000} src={logo} alt="logo" />
          </div>
        </div>
        {/* loading section end */}
        {/* onboarding section start */}
        <section className="onboarding-section highlight se" id="onboardingBody">
          <div className="swiper onboarding">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="poster-wapper">
                  <div className="poster-box-chain" data-aos="fade-down" data-aos-duration={1000} data-aos-delay={1700}>
                    <span className="left-chain" />
                    <span className="right-chain" />
                  </div>
                  <div className="poster-box color1 box1" data-aos="fade-down" data-aos-duration={1000} data-aos-delay={1700}>
                    <h2>MANAGE</h2>
                    <img className="img-fluid top-line" src="assets/images/svg/lines.svg" alt="lines" />
                  </div>
                  <div className="poster-box color2 box2" data-aos="fade-right" data-aos-duration={2000} data-aos-delay={2000}>
                    <h2>YOUR</h2>
                  </div>
                  <div className="poster-box color1 box3" data-aos="fade-left" data-aos-duration={1000} data-aos-delay={2500}>
                    <h2>FINANCE</h2>
                  </div>
                  <div className="poster-box color2 box4" data-aos="fade-up" data-aos-duration={3000} data-aos-delay={3000}>
                    <h2>WISELY</h2>
                    <img className="img-fluid bottom-line" src={lines_fill} alt="lines" />
                  </div>
                </div>
                <div className="custom-container">
                  <p>The best payment method connects your money to friends, family, brands, and experiences.</p>
                  <div className="d-flex justify-content-between align-items-center pb-3">
                    <a href="#" className="btn btn-link mt-0 p-0">Skip</a>
                    <div className="onboarding-next">
                      <img className="img-fluid arrow" src="assets/images/svg/arrow-white.svg" alt="arrow" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="poster-wapper">
                  <div className="poster-box-chain poster-box-chain1" data-aos="fade-down" data-aos-duration={1000} data-aos-delay={500}>
                    <span className="left-chain" />
                    <span className="right-chain" />
                  </div>
                  <div className="poster-box poster-box1 color1 box1" data-aos="fade-down" data-aos-duration={1000} data-aos-delay={500}>
                    <h3>Add your all account and manage</h3>
                  </div>
                  <div className="custom-container">
                    <ul className="card-list">
                      <li>
                        <img className="img-fluid card-img card5" src={card1} alt="card" />
                      </li>
                      <li>
                        <img className="img-fluid card-img card4" src={card2} alt="card" />
                      </li>
                      <li>
                        <img className="img-fluid card-img card3" src={card3} alt="card" />
                      </li>
                      <li>
                        <img className="img-fluid card-img card2" src={card4} alt="card" />
                      </li>
                      <li>
                        <img className="img-fluid card-img card1" src={card5} alt="card" />
                      </li>
                    </ul>
                  </div>
                </div>
                <p>Manage your finance in the app your money will safe here</p>
                <div className="custom-container button-group">
                  <a href="signin.html" className="btn theme-btn w-100">Sign in</a>
                  <a href="signup.html" className="btn btn-link mt-3 pb-3">Sign up</a>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}

export default Splash;
