import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';

import { BiSolidLeftArrow } from 'react-icons/bi';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import deposite1 from './assets/theme_asset/deposit.png'
import reward from './assets/theme_asset/reward.png'



import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';






function Team() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/userteamapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
     
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/team');
    }
    feather.replace();

    fetchData();
  }, []);


  
  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

    // Find the maximum value of total_transaction_sum in the my_downline array
const maxTransactionSum = data.my_downline && data.my_downline.length > 0
// Use reduce to find the maximum value
? data.my_downline.reduce((max, current) => Math.max(max, current.total_transaction_sum), 0)
// If my_downline is empty or undefined, set maxTransactionSum to 0
: 0;


  
 
    return (


      <body className=''>
      <div >
          

      <div className="custom-container">
        <div className='row'> 
          <div className='col-2'>
          <Link to="/" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'125px', height:'auto'}}/></div>
    </div>
    </div>
       
      
    <section><br/>
  <div className="custom-container">
  <center>
        <div>
          
        </div>
        <div className='card-1'>
          Team Business <br/>
          $ {maxTransactionSum}
        </div></center>
    <div className="title" style={{justifyContent:'center',marginTop:'20px'}}>
        <center><h2 >My Team</h2></center>
    </div>
    {data.my_downline && data.my_downline.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.my_downline.map((downline, index) => (
        <div key={index} className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid" src={reward} alt="Profile" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{downline.first_name}</h5>
                  <h3>{downline.is_active}</h3> {/* Add mobile here */}
                </div>
                <div className="transaction-name">
                  <h5 >Level {downline.level}</h5>
                  <h3 style={{color:'black'}}>$ {downline.transaction_sum}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No downline data available</div>
)}


          
       </div>
       </section>
       </div>
       </body>
  );
  }

  export default Team;
