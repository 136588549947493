import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo.png';
import feather from 'feather-icons';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SkeletonLoader from './SkeletonLoader';
import reward from './assets/theme_asset/reward.png';
import Pagination from './react-pegination';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './game.css';
import Modal from "./modal";

function Level_report() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoi, setSelectedRoi] = useState(null);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [Roiid, setRoiid] = useState('');
  const [transfer, setTransfer] = useState('');
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [modalMessage, setModalMessage] = useState({});
  const [showModal, setShowModal] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/roipageapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
      // console.log(response.data)
      const totalAmount1 = response.data.wallet.remaining_roi;
      setTotalAmount1(totalAmount1);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  useEffect(() => {
    const storedMessage = localStorage.getItem('modalMessage3');
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      localStorage.removeItem('modalMessage3');
    }

    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/login');
    } else {
      navigate('/report');
    }
    feather.replace();
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'amount') setAmount(value);
    if (name === 'transfer') setTransfer(value);
  };

  const handleSubmit = async (e, totalAmount1) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('amount', amount);
      formData.append('roiid', Roiid);
      formData.append('transfer', transfer);
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);

      if (totalAmount1 < amount) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To withdraw bonus, please check the available balance',
        });
        setShowModal(true);
        return;
      }

      const response = await axios.post(`${apiBaseUrl}/roitransfer`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsModalOpen(false);
      setModalMessage({
        title: 'Amount Transferred Successfully',
        content: 'Now You Can Use This Amount',
      });
      setShowModal(true);
      navigate('/report');
      fetchData();
    } catch (error) {
      console.error('Error updating user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Something went wrong. Please insert all details or try again later',
      });
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    
  };

  const closeModal1 = () => {
    setIsModalOpen(false);
    
  };

  

  const handleTransfer = (roi, e) => {
    e.preventDefault();
    setSelectedRoi(roi);
    setAmount(roi.amount);
    setRoiid(roi.id);
    setIsModalOpen(true);
  };

  const hasThirtyDaysPassed = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const thirtyDays = 30 * 24 * 60 * 60 * 1000;
    return currentDate - createdDate > thirtyDays;
  };

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data == null) {
    return <SkeletonLoader showHeader={false} showFooter={false} />;
  }

  return (
    <div>
           {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <div className="custom-container">
        <div className='row'>
          <div className='col-2'>
            <Link to="/" style={{ textDecoration: 'none', marginTop: '15px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{ marginTop: '5px' }}>
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
              </svg>
            </Link>
          </div>
          <div className='col-10' style={{ padding: '5px 0 0 80px', fontSize: '15px', color: '#42025a' }}>
            <img src={logo} alt='logo' style={{ width: '125px', height: 'auto' }}/>
          </div>
        </div>
      </div>

      <section><br/>
        <div className="custom-container">
        <div className="row">
        <div className='' >
        <center>
        <div>
          
        </div>
        <div className='card-1'>
            Staking Amount <br/>
            $ {data && data.wallet && data.wallet.revenue} 
        </div></center></div>
        {/* <div className='col-6' style={{paddingLeft:'2px'}}><center>
        <div>
          
        </div>
        <div className='card-1'>
          Available Profit <br/>
          $ {data.wallet.remaining_roi} 
        </div></center></div> */}
        </div>
        {/* <form className="auth-form-1" onSubmit={(e) => handleSubmit(e, totalAmount1)} encType="multipart/form-data">
        <div className="custom-container">
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label">Amount</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Where to Transfer</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="transfer"
                name="transfer"
                onChange={handleChange}
                required
                >
                <option value="">Select where to Transfer</option>
                <option value="withdraw">Transfer to Withdraw</option>
                <option value="playWallet">Transfer to Play Wallet</option>
                </select>

                
                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> 
        </div>
               
              
            </div>
          </div>
          
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>{transfer === '' && 'Transfer'}{transfer === 'withdraw' && 'Transfer to Withdraw Wallet'}{transfer === 'playWallet' && 'Transfer to Play Wallet' } </button>
        </div>
      </form> */}
          <div className="title" style={{ justifyContent: 'center', marginTop: '20px' }}>
            <center><h2>My Staking Amount</h2></center>
          </div>
          <center><h5 style={{color:'red'}}>For Daily ROI Report please click on tabs</h5></center>
          {data.Roi_report.data && data.Roi_report.data.length > 0 ? (
            <section>
              <div className="custom-container">
              {data.Roi_report.data.map((roi) => (
                  
                      <div key={roi.id} className="col-12">
                        <div className="transaction-box">
                          <div className="d-flex gap-3">
                            <Link to={`/roi_report/${roi.id}`}>
                              <div className="transaction-image">
                                <img className="img-fluid" src={reward} alt="reward" />
                              </div>
                            </Link>
                            <div className="transaction-details">
                            <Link to={`/roi_report/${roi.id}`}>
                               <div className="transaction-name">
                                <h5>{roi.reason === 'roi_staking' ? 'Deposite Amount' : 'Received From Fund Manager'}</h5>
                                <h3 className={roi.status === 'pending' ? 'text-black' : roi.status === 'reject' ? 'error-color' : 'success-color'}>
                                  {roi.reason === 'withdraw_payment_usdt' ? '$' : '$'}{roi.amount}
                                </h3>
                              </div>
                              </Link>
                              <div className="transaction-name">
                                <h5>
                                  {new Date(roi.created_at).toLocaleDateString([], {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit',
                                  })}{' '}
                                  {new Date(roi.created_at).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })}
                                </h5>
                                
                                  <h3 className={roi.status === 'pending' ? 'text-black' : roi.status === 'reject' ? 'error-color' : 'success-color'}>
                                    {roi.status}
                                  </h3>
                           
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                  ))}
                {data.Roi_report.links && (
                  <Pagination
                    pageCount={data.Roi_report.last_page}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </section>
          ) : (
            <div className="custom-container">
              <div className="no-transactions">
                <p>No Transactions</p>
              </div>
            </div>
          )}
         
        </div>
      </section>
    </div>
  );
}

export default Level_report;
