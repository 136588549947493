import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';


import './App.css';

import logo1 from './assets/theme_asset/logo/logo.png';
import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import home_bg from './assets/theme_asset/background/home-bg1.jpg';

import logo from './assets/theme_asset/logo.png';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      
     
    } catch (error) {

    } finally {

    }
  };
  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token exists, navigate to login
      navigate('/login');
    }

    // Replace icons after component is mounted
    feather.replace();
    fetchData();
    
  }, []);


  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
          
  return (
    <body className=''>
    <div className=''>
      <div className={`offcanvas sidebar-offcanvas offcanvas-start${isSidebarOpen ? ' show' : ''}`} tabIndex={-1} id="offcanvasLeft">
          <div className="offcanvas-header sidebar-header">
          <a  onClick={closeSidebar}>
            <i className="categories-icon"  data-feather="x" />
                  </a>

            <div className="sidebar-logo">
              <img className="img-fluid logo" src={logo} alt="logo" style={{margin:'60px 0 50px 0'}} />
              
            </div>
             
            <div className="balance">
              <img className="img-fluid balance-bg" src={home_bg} alt="auth-bg" />
              <h2>Stake Bet</h2>
              <h5>Welcome to Stake Bet</h5>
            </div>
             
          
            
          </div>
          <div className="offcanvas-body">
            <div className="sidebar-content">
              <ul className="link-section">
                <li>
                  <Link to="/" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73" />
</svg>
                    <h3>Home</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/profile" className="pages">
                  <svg className="active" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 14 14">
                    <path fill="red" fill-rule="evenodd" d="M1.573 1.573A.25.25 0 0 1 1.75 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5A1.75 1.75 0 0 0 0 1.75v1.5a.75.75 0 0 0 1.5 0v-1.5a.25.25 0 0 1 .073-.177M14 10.75a.75.75 0 0 0-1.5 0v1.5a.25.25 0 0 1-.25.25h-1.5a.75.75 0 0 0 0 1.5h1.5A1.75 1.75 0 0 0 14 12.25zM.75 10a.75.75 0 0 1 .75.75v1.5a.25.25 0 0 0 .25.25h1.5a.75.75 0 0 1 0 1.5h-1.5A1.75 1.75 0 0 1 0 12.25v-1.5A.75.75 0 0 1 .75 10m10-10a.75.75 0 0 0 0 1.5h1.5a.25.25 0 0 1 .25.25v1.5a.75.75 0 0 0 1.5 0v-1.5A1.75 1.75 0 0 0 12.25 0zM7 7.776a4.423 4.423 0 0 0-4.145 2.879c-.112.299.127.595.446.595h7.396c.32 0 .558-.296.447-.595a4.423 4.423 0 0 0-4.145-2.879Zm2.208-3.315a2.21 2.21 0 1 1-4.421 0a2.21 2.21 0 0 1 4.421 0" clip-rule="evenodd" />
                  </svg>
                    <h3>Profile</h3>
                  </Link>
                </li>
                <li>
                  <a href="https://newbackend.stakebet.in/public/stakebet.pdf" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16">
	<g fill="red">
		<path d="M5.523 12.424q.21-.124.459-.238a8 8 0 0 1-.45.606c-.28.337-.498.516-.635.572l-.035.012a.3.3 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36c.106-.165.319-.354.647-.548m2.455-1.647q-.178.037-.356.078a21 21 0 0 0 .5-1.05a12 12 0 0 0 .51.858q-.326.048-.654.114m2.525.939a4 4 0 0 1-.435-.41q.344.007.612.054c.317.057.466.147.518.209a.1.1 0 0 1 .026.064a.44.44 0 0 1-.06.2a.3.3 0 0 1-.094.124a.1.1 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a5 5 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822c.038-.177.11-.248.196-.283a.5.5 0 0 1 .145-.04c.013.03.028.092.032.198q.008.183-.038.465z" />
		<path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419c.207.075.412.04.58-.03c.318-.13.635-.436.926-.786c.333-.401.683-.927 1.021-1.51a11.7 11.7 0 0 1 1.997-.406c.3.383.61.713.91.95c.28.22.603.403.934.417a.86.86 0 0 0 .51-.138c.155-.101.27-.247.354-.416c.09-.181.145-.37.138-.563a.84.84 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.8 5.8 0 0 0-1.335-.05a11 11 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794c.036-.218.055-.426.048-.614a1.24 1.24 0 0 0-.127-.538a.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077c-.377.15-.576.47-.651.823c-.073.34-.04.736.046 1.136c.088.406.238.848.43 1.295a20 20 0 0 1-1.062 2.227a7.7 7.7 0 0 0-1.482.645c-.37.22-.699.48-.897.787c-.21.326-.275.714-.08 1.103" />
	</g>
</svg>
                    <h3>Download Plan</h3>
                  </a>
                </li>
                <li>
                  <Link to="/deposite" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M15 3a1 1 0 0 1 1 1v2h4a1 1 0 0 1 1 1v12h2v2H1v-2h2V7a1 1 0 0 1 1-1h4V4a1 1 0 0 1 1-1zm-5 5H8v11h2zm6 0h-2v11h2zm-2-3h-4v1h4z" />
</svg>
                    <h3>Deposit</h3>
                  </Link>
                </li>

                <li>
                  <Link to="/fund_manager_list" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
	<g fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
		<ellipse cx="14" cy="10" rx="10" ry="5" />
		<path d="M4 10v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
		<path d="M4 17v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
		<path d="M4 24v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
		<path d="M4 31v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
		<ellipse cx="34" cy="24" rx="10" ry="5" />
		<path d="M24 24v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
		<path d="M24 31v7c0 2.761 4.477 5 10 5s10-2.239 10-5v-7" />
	</g>
</svg>
                    <h3>Fund Manager's</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="page-list.html" className="pages">
                    <i className="sidebar-icon" data-feather="file-text" />
                    <h3>Theme Pages</h3>
                  </Link>
                </li> */}
               
                <li>
                  <Link to="/profit" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none">
		<path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
		<path fill="red" d="M22 10v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-7zm-4 4h-3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2m1-10a3 3 0 0 1 3 3v1H2V7a3 3 0 0 1 3-3z" />
	</g>
</svg>
  <h3>My Profit</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/report" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M12.3 8.93L9.88 6.5h5.62V10H17V5H9.88l2.42-2.43l-1.06-1.07L7 5.75L11.24 10zM12 14a3 3 0 1 0 3 3a3 3 0 0 0-3-3m-9-3v12h18V11m-2 8a2 2 0 0 0-2 2H7a2 2 0 0 0-2-2v-4a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2Z" />
</svg>
                    <h3>ROI Income Report</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/bonus" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<circle cx="12" cy="12" r="10" />
		<path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8m4 2V6" />
	</g>
</svg>
                    <h3>Team Rewards</h3>
                  </Link>
                </li>
               
                <li>
                  <Link to="/direct_team" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<g fill="red">
		<path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0a1.674 1.674 0 0 1 3.349 0m-2.238 9.488l-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z" />
		<path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z" />
	</g>
</svg>
                    <h3>My Direct Team</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="red" d="M8 2.002a1.998 1.998 0 1 0 0 3.996a1.998 1.998 0 0 0 0-3.996M12.5 3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3M5 7.993A1 1 0 0 1 6 7h4a1 1 0 0 1 1 1v3a3.02 3.02 0 0 1-.146.927A3.001 3.001 0 0 1 5 11V8zM4 8c0-.365.097-.706.268-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 0 0 3.436 2.319A3.983 3.983 0 0 1 4 10.999zm8 0v3c0 .655-.157 1.273-.436 1.819A2.5 2.5 0 0 0 15 10.5V8a1 1 0 0 0-1-1h-2.268c.17.294.268.635.268 1" />
</svg>
                    <h3>Team</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2" />
</svg> <h3>Stake Bet Newsfeed</h3>
                  </Link>
                </li>
               
                <li>
                  <Link to="/about" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10" />
</svg>
                    <h3>Support</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="red" d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2" />
</svg>
                    <h3>About us</h3>
                  </Link>
                </li> */}
                <li>
                <Link to="#" onClick={handleLogout} className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 1024 1024">
	<path fill="red" d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8c-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9a353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8c47.9 0 94.3 9.3 137.9 27.8c42.2 17.8 80.1 43.4 112.7 75.9c7.9 7.9 15.3 16.1 22.4 24.5c3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82C271.7 82.6 79.6 277.1 82 516.4C84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7c3.4-5.3-.4-12.3-6.7-12.3m88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6" />
</svg>
                    <h3>Log out</h3>
                  </Link>
                </li>
              </ul>
              {/* <h2 style={{color:'black' ,marginTop:'15px'}}>Contact us</h2> 
            <div>Support:<a href='mailto:wazir.in24@gmail.com'> wazir.in24@gmail.com</a></div> 
            <div>Whatsapp:<a href='https://wa.me/12135945630'> https://wa.me/12135945630</a></div> */}
            </div>
          </div>
        </div>
      <header className="section-t-space">
      
        <div className="custom-container" style={{paddingTop:'10px'}}>
          <div className="header-panel">
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img className="img-fluid logo" src={logo} style={{width:'120px' }} alt="logo" />
            <a href='https://newbackend.stakebet.in/public/base.apk' style={{textAlign:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
	<g fill="none" stroke="red" stroke-linecap="round" stroke-width="2">
		<path stroke-dasharray="2 4" stroke-dashoffset="6" d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21">
			<animate attributeName="stroke-dashoffset" dur="0.6s" repeatCount="indefinite" values="6;0" />
		</path>
		<path stroke-dasharray="30" stroke-dashoffset="30" d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3">
			<animate fill="freeze" attributeName="stroke-dashoffset" begin="0.1s" dur="0.3s" values="30;0" />
		</path>
		<path stroke-dasharray="10" stroke-dashoffset="10" d="M12 8v7.5">
			<animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="10;0" />
		</path>
		<path stroke-dasharray="6" stroke-dashoffset="6" d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5">
			<animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0" />
		</path>
	</g>
</svg>
<div>
Download
</div>
            </a>
            </div>
        </div>
      </header>
    </div>
    </body>
  );
}

export default Sidebar;

                 
                