import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';
import { BiClipboard } from 'react-icons/bi';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import color from './assets/theme_asset/color.png';
import number from './assets/theme_asset/number.png';
import card1 from './assets/theme_asset/card1.png';
import game1 from './assets/theme_asset/1.png';
import game3 from './assets/theme_asset/2.png';
import card3 from './assets/theme_asset/10.png';
import card4 from './assets/theme_asset/11.png';
import card5 from './assets/theme_asset/12.png';
import card6 from './assets/theme_asset/13.png';

import card2 from './assets/theme_asset/card2.png';
import game from './assets/theme_asset/game.jpg';
import ban1 from './assets/theme_asset/ban1.png';
import ban2 from './assets/theme_asset/ban2.png';
import l49 from './assets/theme_asset/logo/48.png';
import popup from './assets/theme_asset/wazir_img.jpg'

import search from './assets/search (1).png';

import stakebet from './assets/theme_asset/stakebet.png';

import './App.css';
import './game.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';






function App() {

  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  const [bbnPrice, setBbnPrice] = useState(null);

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  



const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black'
    ],
    hoverOffset: 4
  }]
};


      




const fetchData = async () => {
  try {
    const id = localStorage.getItem('id');
    // console.log(id);

    // Function to check if the ID is encrypted
    const isEncrypted = (id) => {
      // Example: Check if the ID is a Base64 string and has a specific length
      const base64Regex = /^[A-Za-z0-9+/=]+$/;
      return typeof id === 'string' && id.length > 16 && base64Regex.test(id);
    };

    if (id && isEncrypted(id)) {
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      // console.log(response.data);
      if (response) {
        setData(response.data);
      } else {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  } catch (error) {
    setError(error);
    navigate('/login'); // Navigate to login on error
  } finally {
    setLoading(false);
  }
};



  const copyToClipboard = () => {
    const userEmail = data.user.email; // Replace this with the actual user's email
const textToCopy = `stakebet.in/register?email=${encodeURIComponent(userEmail)}`;

  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      
  
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };

  useEffect(() => {
    // Replace icons after component is mounted
    

       
    
    const token = localStorage.getItem('token');

    // console.log(token);
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/');
      
    }
    feather.replace();

    fetchData();
  }, []);

 


  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={true} showFooter={true} /> 

       )
    }

   
    const registerLink = `https://stakebet.in/register?email=${data.user.email}`;
  
 
    return (
      <body className=''>
      <div className=''>
    
       
       <Sidebar/>

       <div className="marquee-container" style={{marginTop:'25px',marginBottom:'-20px',backgroundColor:'#f90119',height:'25px',
                                                 fontSize:'18px',color:'white'}}>
      <div className="marquee">
      <span>Welcome to Stake Bet </span>
      <span>{data.setting.marquee} </span>
      {/* <span>{data.setting.marquee} </span> */}
      {/* <span>Your scrolling text goes here. </span>
      <span>Your scrolling text goes here. </span> */}
        
        {/* Add more spans with your text */}
      </div>
    </div>
       
       {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
       <br/>
       
<section className="">
  <div className="custom-container">
    
    <div className="card-box" style={{marginTop:'5px'}}>
      <div className="card-details">
        <div className="d-flex justify-content-between">
          <h5 className="fw-semibold">Play Wallet Balance</h5>
          <img src={ellipse} alt="ellipse" />
        </div>
        <h1 className="mt-2 text-white">$ {data.wallet.wallet_balance} </h1>
        <div className="amount-details" style={{marginTop:'30px'}}>
          <div className='row'>
            
          <div className="col-12 text-start">
          <h3 style={{color:'white'}}>Hello </h3>
            <h3 style={{color:'white'}}>{data.user.name}</h3><br />
           
      
          </div>
          
            </div>

        </div>
      </div>
      
    </div>
    <div className="d-flex align-items-center justify-content-center">
    <Link to="/deposite" style={{marginRight:'50px', paddingTop:'11px',marginLeft:'-20px'}}>
        <button className='botton' style={{
        padding: '11px 10px' , 
        width: '150%' ,
        marginBottom: '15%',
        borderRadius: '20px',
        backgroundColor: 'red',
        border: 'none',
        color: 'white'}}>Deposit</button></Link>
        <Link to='/withdraw'>
        <button style={{ 
          padding: '10px' , 
        width: '120%' ,
        borderRadius: '20px',
        backgroundColor: 'goldenrod',        
        border: 'none',
        color: 'white'
        }}>Withdraw</button></Link>
    </div>
  </div>
</section>
<div className="custom-container">
<div className='row'>
            
            <div className="col-8 text-start">
              <div className="d-flex align-items-center justify-content-start">
              
                <h5 style={{color:'black'}}>Refferal Link:</h5>
              </div>
              <a href={registerLink}>www.stakebet.in/register?email={data.user.email}</a><br />
             
        
            </div>
            <div className="col-4 text-start">
              <div className="d-flex ">
              
              {!copied && (
    <button className="btn d-flex btn-link" style={{color:'black'}} onClick={copyToClipboard}>
      Copy <BiClipboard style={{ marginLeft: '2px', color: 'black' }} />
    </button>
  )}
  {copied && <span className="text-success">Copied!</span>}
  
  
                  </div>
              
              </div>
              </div></div>
<section className="categories-section section-b-space">
  <div className="custom-container">
    <ul className="categories-list">
      <li>
        <Link to="/profit">
        <h5 className="mt-2 text-center">Profit</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 32 32">
	<path fill="red" d="M21 12v-2h-4V7h-2v3h-2a2.002 2.002 0 0 0-2 2v3a2.002 2.002 0 0 0 2 2h6v3h-8v2h4v3h2v-3h2a2.002 2.002 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2h-6v-3Z" />
	<path fill="red" d="M16 4A12 12 0 1 1 4 16A12.035 12.035 0 0 1 16 4m0-2a14 14 0 1 0 14 14A14.041 14.041 0 0 0 16 2" />
</svg></div>
<h5 className="mt-2 text-center">{data.wallet.win_balance}</h5>
        </Link>
      </li>
      <li>
      <Link to="/report">
      <h5 className="mt-2 text-center">Staking</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 36 36">
	<path fill="red" d="m3.5 13.56l1.5-.88V16h26v-3.29l1.48.85a1 1 0 0 0 1-1.73L18 2.92L2.5 11.83a1 1 0 1 0 1 1.73m14.35-6.45a.8.8 0 0 1 .8 0L25.37 11h-3.22l-3.9-2.24l-3.9 2.24h-3.21Z" class="clr-i-solid clr-i-solid-path-1" />
	<path fill="red" d="M32.85 27H32v-.85A1.15 1.15 0 0 0 30.85 25H28v-7.37h-4V25h-4v-7.37h-4V25h-4v-7.37H8V25H5.15A1.15 1.15 0 0 0 4 26.15V27h-.85A1.15 1.15 0 0 0 2 28.15V31h32v-2.85A1.15 1.15 0 0 0 32.85 27" class="clr-i-solid clr-i-solid-path-2" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.availabe_revenue}</h5>
        </Link>
      </li>
      <li>
      <Link to="/bonus">
      <h5 className="mt-2 text-center">Rewards</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 24 24">
	<g fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<circle cx="12" cy="12" r="10" />
		<path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8m4 2V6" />
	</g>
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.availabe_level}</h5>
        </Link>
      </li>
      <li>
      <Link to="/Withdraw">
      <h5 className="mt-2 text-center">Withdraw</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 16 16">
	<g fill="red">
		<path fill-rule="evenodd" d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm9 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0m-6.25-.75a.75.75 0 1 0 0 1.5a.75.75 0 0 0 0-1.5M11.5 6A.75.75 0 1 1 13 6a.75.75 0 0 1-1.5 0" clip-rule="evenodd" />
		<path d="M13 11.75a.75.75 0 0 0-1.5 0v.179c0 .15-.138.28-.306.255A65.277 65.277 0 0 0 1.75 11.5a.75.75 0 0 0 0 1.5c3.135 0 6.215.228 9.227.668A1.764 1.764 0 0 0 13 11.928z" />
	</g>
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.withdraw_wallet}</h5>
          
        </Link>
      </li>
    </ul>
  </div>
</section>



<section>
<div className="custom-container" >
<div className="card-container10">
    <div className="card10">
        <Link to="/game1" className="card-link10">
            <img src={card1} alt="color" className="card-image10" />
            <h3 className="card-title10">Predict & Win</h3>
        </Link>
    </div>
    <div className="card10">
        <Link to="#" className="card-link10">
            <img src={card2} alt="color" className="card-image10" />
            <h3 className="card-title10">Comming Soon</h3>
        </Link>
    </div>
</div>

<div className="card-container10">
    <div className="card10">
        <Link to="#" className="card-link10">
        <img src={card3} alt='color' className='card-image10'/>
     <h3 className='card-title10'>Comming Soon</h3>
        </Link>
    </div>
    <div className="card10">
        <Link to="#" className="card-link10">
            <img src={card4} alt="color" className="card-image10" />
            <h3 className="card-title10">Comming Soon</h3>
        </Link>
    </div>
</div>

<div className="card-container10">
    <div className="card10">
        <Link to="#" className="card-link10">
        <img src={card5} alt='color' className="card-image10"  />
     <h3 className='card-title10'>Comming Soon</h3>
        </Link>
    </div>
    <div className="card10">
        <Link to="#" className="card-link10">
            <img src={card6} alt="color" className="card-image10" />
            <h3 className="card-title10">Comming Soon</h3>
        </Link>
    </div>
</div>
  </div>
  
</section> 


<section className="custom-container card11" style={{padding:'10px 25px'}}>
<div className='d-flex justify-content-between'>
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
	<g fill="none">
		<circle cx="16" cy="16" r="16" fill="#f3ba2f" />
		<path fill="#fff" d="M12.116 14.404L16 10.52l3.886 3.886l2.26-2.26L16 6l-6.144 6.144zM6 16l2.26-2.26L10.52 16l-2.26 2.26zm6.116 1.596L16 21.48l3.886-3.886l2.26 2.259L16 26l-6.144-6.144l-.003-.003zM21.48 16l2.26-2.26L26 16l-2.26 2.26zm-3.188-.002h.002V16L16 18.294l-2.291-2.29l-.004-.004l.004-.003l.401-.402l.195-.195L16 13.706z" />
	</g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
	<g fill="none" fill-rule="evenodd">
		<circle cx="16" cy="16" r="16" fill="#627eea" />
		<g fill="#fff" fill-rule="nonzero">
			<path fill-opacity="0.602" d="M16.498 4v8.87l7.497 3.35z" />
			<path d="M16.498 4L9 16.22l7.498-3.35z" />
			<path fill-opacity="0.602" d="M16.498 21.968v6.027L24 17.616z" />
			<path d="M16.498 27.995v-6.028L9 17.616z" />
			<path fill-opacity="0.2" d="m16.498 20.573l7.497-4.353l-7.497-3.348z" />
			<path fill-opacity="0.602" d="m9 16.22l7.498 4.353v-7.701z" />
		</g>
	</g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
	<g fill="none" fill-rule="evenodd">
		<circle cx="16" cy="16" r="16" fill="#30c0f2" />
		<path fill="#fff" fill-rule="nonzero" d="M10.123 7h11.774s.312 1.554 1.98 1.658c0 0 5.522 12.85-7.919 18.342c0 0-12.399-3.938-8.023-18.238c0 0 1.876-.104 2.188-1.762m11.15 1.023h-10.52l-.014.224c-.088.489-.333.83-.692 1.06a1.89 1.89 0 0 1-.732.271l-.184.018l-.052.178c-.969 3.31-1.002 6.17-.239 8.61c.626 2 1.759 3.64 3.252 4.95a12.48 12.48 0 0 0 2.595 1.753c.31.157.6.286.859.39c.157.063.272.104.335.125l.098.03l.095-.04c3.706-1.584 5.948-3.978 6.952-6.96c.83-2.46.756-5.079.137-7.559a14.225 14.225 0 0 0-.43-1.406a4.272 4.272 0 0 0-.042-.109l-.064-.158l-.171-.011c-.486-.032-.795-.23-.986-.548a1.367 1.367 0 0 1-.185-.56zm-10.045.545c6.367.001 9.55.002 9.55.003c.044.187.115.373.22.55c.256.425.667.712 1.243.794a13.684 13.684 0 0 1 .39 1.29c.596 2.388.666 4.906-.125 7.254c-.945 2.807-3.046 5.067-6.551 6.59a10.218 10.218 0 0 1-1.019-.449a11.936 11.936 0 0 1-2.481-1.674c-1.421-1.248-2.497-2.804-3.091-4.703c-.718-2.295-.699-4.993.194-8.134a2.556 2.556 0 0 0 .785-.324a2.03 2.03 0 0 0 .885-1.197m7.36 5.582h-.303c.03-.608-.105-1.932-1.75-2.3a2.612 2.612 0 0 0-1.444.066c-.642.228-1.408.801-1.437 2.234h-.223a.86.86 0 0 0-.86.855v3.575a.86.86 0 0 0 .86.855h5.158a.86.86 0 0 0 .86-.855v-3.575a.86.86 0 0 0-.86-.855zm-2.005 4.042h-1.146l.303-1.507a.673.673 0 0 1 .27-1.291a.673.673 0 1 1 .27 1.291zm-2.188-4.042c.028-.468.226-1.576 1.459-1.658c0 0 1.626-.089 1.597 1.658z" />
	</g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
	<g fill="none" fill-rule="evenodd">
		<circle cx="16" cy="16" r="16" fill="#f7931a" />
		<path fill="#fff" fill-rule="nonzero" d="M23.189 14.02c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84l-1.728-.43l-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009l-2.384-.595l-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045l-1.13 4.532c-.086.212-.303.531-.793.41c.018.025-1.256-.313-1.256-.313l-.858 1.978l2.25.561c.418.105.828.215 1.231.318l-.715 2.872l1.727.43l.708-2.84c.472.127.93.245 1.378.357l-.706 2.828l1.728.43l.715-2.866c2.948.558 5.164.333 6.097-2.333c.752-2.146-.037-3.385-1.588-4.192c1.13-.26 1.98-1.003 2.207-2.538m-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11m.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733" />
	</g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
	<g fill="none">
		<circle cx="16" cy="16" r="16" fill="#ef0027" />
		<path fill="#fff" d="M21.932 9.913L7.5 7.257l7.595 19.112l10.583-12.894zm-.232 1.17l2.208 2.099l-6.038 1.093zm-5.142 2.973l-6.364-5.278l10.402 1.914zm-.453.934l-1.038 8.58L9.472 9.487zm.96.455l6.687-1.21l-7.67 9.343z" />
	</g>
</svg>
</div>
</section>
<section className="panel-space" />

        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>

</div>

</body>
  
  );
  }

  export default App;
