import React, { useState } from "react";
import MyModal from "./mymodal";

import "./modal.css";

const Modal = ({ message, closeModal }) => {
  const handleCloseButton = (
    <button className="model-btn" style={{marginTop:'10px'}} onClick={closeModal}>
      Accept It
    </button>
  );
  // Function to capitalize first letter of a string
  function capitalizeFirstLetter(text) {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const mainModal = (
    <MyModal closeModal={closeModal} handleCloseButton={handleCloseButton}>
      <h2>{message.title}</h2>
      <p className="modal_msg">
        {capitalizeFirstLetter(message.content)}
      </p>
    </MyModal>
  );

  return <>{mainModal}</>;
};

export default Modal;