import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Footer from './footer';
import search from './assets/search (1).png';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './About.css';
import SkeletonLoader from './SkeletonLoader';

import logo from './assets/theme_asset/logo1.png';


function About() {
    const [setting, setSetting] = useState(null); // Declare setting variable here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
            setSetting(response.data.setting);
            // console.log(response.data.setting)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return  <SkeletonLoader showHeader={false} showFooter={false} />  ;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    

    return (
      <body className='' style={{overflow:'hidden'}}>

        
        <div>

<div className="auth-header">
   
    <form className="theme-form p-2" target="_blank">
            <div className="form-group mt-2" >
                <div className="form-input" >
                <div>
                <div className='row'> 
          <div className='col-2'>
          <Link to="/" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'125px', height:'auto'}}/></div>
    </div>
          </div>
                </div>
            </div>
        </form>
    <div className="auth-content">
        
        <br />
        <div>
            <h1 className='text-white'>{setting.about_title} </h1>
           
        </div>
    </div>
</div>
          

        <div className="about-section" style={{background:'linear-gradient(90deg, #fb011c, #dd0103)'}}>
  <div className="about-item">
  <div style={{color: 'white'}} dangerouslySetInnerHTML={{ __html: setting.about_desc }} />
            
  </div>
  <div className="about-item">
  <div style={{color: 'white'}}>
    <b>Facebook:</b> <a target="_blank" href={`${setting.facebook}`} style={{color:'white'}}>{setting.facebook}</a> 
  </div>
  <div style={{color: 'white'}}>
    <b>Instgram:</b> <a target="_blank" href={`${setting.instagram}`} style={{color:'white'}}>{setting.instagram}</a> 
  </div>    
  </div>
  <br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
 
</div> 


            
        </div>
        </body>
    );
}

export default About;
