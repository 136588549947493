import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import feather from 'feather-icons';
import SkeletonLoader from './SkeletonLoader';
import Pagination from './react-pegination';
import logo from './assets/theme_asset/logo.png';
import reward from './assets/theme_asset/reward.png';
import './game.css';

function Fund_Manager_list() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/fundmanagerapi?page=${pageNumber + 1}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
    feather.replace();
    fetchData();
  }, [pageNumber, navigate]);

  

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <SkeletonLoader showHeader={false} showFooter={false} />;
  }

  return (
    <div>
      <div className="custom-container">
        <div className="row">
          <div className="col-2">
            <Link to="/" style={{ textDecoration: 'none', marginTop: '15px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{ marginTop: '5px' }}>
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
              </svg>
            </Link>
          </div>
          <div className="col-10" style={{ padding: '5px 0 0 80px', fontSize: '15px', color: '#42025a' }}>
            <img src={logo} alt="logo" style={{ width: '125px', height: 'auto' }} />
          </div>
        </div>
      </div>

      <section><br />
        <div className="custom-container">
          <div className="title" style={{ justifyContent: 'center', marginTop: '20px' }}>
            <center><h2>Fund Managers</h2></center>
          </div>
          {data.fundmanager.data && data.fundmanager.data.length > 0 ? (
            <section>
              <div className="custom-container">
                <section>
                  <div className="custom-container">
                    {data.fundmanager.data.map((manager, index) => (
                      <div key={index} className="col-12">
                        <div className="transaction-box">
                          <a href="#" className="d-flex gap-3">
                            <div className="transaction-image">
                              <img className="img-fluid" src={reward} alt="Profile" />
                            </div>
                            <div className="transaction-details">
                              <div className="transaction-name">
                                <h5>{manager.name}</h5>
                                <a href={`tel:${manager.mobile}`}><h3 style={{ color: 'black' }}>{manager.mobile}</h3></a>
                              </div>
                              <div className="transaction-name">
                              <a href={`mailto:${manager.email}`}><h5>{manager.email}</h5></a>
                             <h3>{manager.is_active}</h3>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>

                {data.fundmanager.links && (
                  <Pagination
                    pageCount={data.fundmanager.last_page}
                    onPageChange={handlePageClick}
                  />
                )}
              </div>
            </section>
          ) : (
            <div style={{ textAlign: 'center' }}>Fund Manager Not available</div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Fund_Manager_list;
