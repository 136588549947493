import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import authentication6 from '../assets/theme_asset/authentication/6.svg';



function Signup() {

  useEffect(() => {
    // Replace icons after component is mounted
    feather.replace();
  }, []);

  

          
  return (
    <div>
        <div className="auth-header">
          <img className="img-fluid img" src={authentication6} alt="v1" />
          <div className="auth-content">
            <div>
              <h2>Welcome back !!</h2>
              <h4 className="p-0">Fill up the form</h4>
            </div>
          </div>
        </div>
        {/* header end */}
        {/* login section start */}
        <form className="auth-form" target="_blank">
          <div className="custom-container">
            <div className="form-group">
              <label htmlFor="inputname" className="form-label">Full name</label>
              <div className="form-input">
                <input type="text" className="form-control" id="inputname" placeholder="Enter your name" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputusername" className="form-label">Email id</label>
              <div className="form-input">
                <input type="email" className="form-control" id="inputusername" placeholder="Enter Your Email" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="newpin" className="form-label">Enter new pin</label>
              <div className="form-input">
                <input type="number" className="form-control" id="newpin" placeholder="Enter pin" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="confirmpin" className="form-label">Re-enter new pin</label>
              <div className="form-input">
                <input type="number" className="form-control" id="confirmpin" placeholder="Re-enter pin" />
              </div>
            </div>
            <div className="remember-option mt-3">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">I agree to all terms &amp; condition</label>
              </div>
            </div>
            <a href="personal-identity.html" className="btn theme-btn w-100">Sign up</a>
            <h4 className="signup">Already have an account ?<a href="signin.html"> Sign in</a></h4>
          </div>
        </form>
    </div>
  );
}

export default Signup;
