import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Notification() {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  







      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data); 
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  


  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }

  
  return (

    
    <div>
       

  

{/* categories section starts */}

       
<header className="section-t-space">
    <div className="custom-container">
      <div className="header-panel">
        <Link to="/" className="back-btn">
          X
        </Link>
        
      </div>
    </div>
  </header>
        {/* quick send section end */}
        {/* Transaction section starts */}
        <section className="section-b-space">
    <div className="custom-container">
      <div className="title" style={{justifyContent:'center'}}>
        <h2>All Notification</h2>
      </div>
      <ul className="notification-list">
      {data.notification && data.notification.length > 0 ? (
  <ul>
    {data.notification.map((notification, index) => (
      <li key={index} className="notification-box">
        <div className="notification-img">
          <img className="img-fluid icon" src={p1} alt="p1" />
        </div>
        <div className="notification-details">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <a href="#">
                <h5 className="fw-semibold dark-text">{notification.title}</h5>
              </a>
              <h5 className="dark-text fw-normal">
                {notification.desc}
              </h5>
            </div>
            <h6 className="time fw-normal light-text">9:02 pm</h6>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <h6 className="fw-normal light-text mt-1">{notification.to_whome}</h6>
          </div>
        </div>
      </li>
    ))}
  </ul>
) : (
  <div style={{textAlign:'center'}}>No notification data available</div>
)}

              
      </ul>
    </div>
  </section>
        {/* all cards section end */}
       
        {/* saving plans section starts */}
        
       
        
        
      </div>
  );
}

export default Notification;
