import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.png';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import reward from './assets/theme_asset/reward.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';
import Pagination from './react-pegination';
import Swal from 'sweetalert2'; 
import withReactContent from 'sweetalert2-react-content';



const MySwal = withReactContent(Swal);


function Transfer() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [email , setemail] = useState('');
  const [password , setpassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [id, setId] = useState(''); // Define and initialize the id state
  const [upi, setUpi] = useState('');
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}?page=${pageNumber + 1}`);
     
      setData(response.data);

    
      const totalAmount1 = response.data.wallet.wallet_balance;
      setTotalAmount1(totalAmount1);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'amount') setAmount(value);
    if (name === 'email') setemail(value);
    if (name === 'password') setpassword(value);


  };

  const fetchData1 = async () => {
    try {
      
      const response1 = await axios.get(`${apiBaseUrl}/userpageapi`);
      const userData = response1.data.user;
      
      let found = false;
     
      // Loop through each user object in the userData array
      userData.forEach(user => {
        // Check if the user's email matches the referral id
        if (user.email === email) {
          found = true;
        }
      });
  
      if (found) {
        MySwal.fire({
          icon: 'success',
          title: 'User ID Found',
          text: 'This User ID exists in the database!',
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'User ID Not Found',
          text: 'This User ID does not exist in the database!',
        });
      }
     
  
    
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleReferral = (e) => {
    const { name,value } = e.target;
   
    if (name === 'email') { // Check if the changed input is the referral ID
      fetchData1(value); // Call fetchData only when referral ID changes
    }
  
    
  };
  const handleSubmit = async (e,totalAmount1) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('email', email);
      formData.append('password', password);
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);
      // Append receipt file to FormData
      
     
      
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/Transferbalance`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });

      
        
      setModalMessage({
        title: 'Amount Transfered Successfully',
        content: `To the user ${data.user.name} with email ${data.user.email}` ,
      });
      setShowModal(true);
      // navigate('/transfer'); 
        setTimeout(() => {
        setIsSubmitting(false);
      }, 2000); // simulate a 2 seconds delay for form submission
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error updating user:', error);
      if (totalAmount1 < amount) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To Transfer Amount,Please check the available balance',
        });
        setShowModal(true);
        return;
      }
      let errorMessage = 'An error occurred';
      if (error.response && error.response.data) {
        errorMessage = error.response.data; // This should give you ["Insufficient Balance"]
      }
      setModalMessage({
        title: 'Transfered Failed!',
        content: `${errorMessage}`,
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };
  
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/transfer');
    }
    feather.replace();
  
    fetchData();
  }, []);
  

  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const reasonMap = {
    'transafer_to_user': 'Tranfers Play Value',
    'roi_staking_transfer': 'Tranfers Staking Value'
  };
  const reasonMap1 = {
    'transafer_to_user': 'Received Play Value',
    'roi_staking_transfer': 'Received Staking Value'
  };
  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (

    <body className='' >
    <div className='' style={{overflow:'hidden'}}>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       
     <Sidebar />
     <section style={{marginTop:'35px'}}>
     <center><h2 style={{color:'black'}} >Wallet Balance</h2> </center>
      
        <center>
        <div>
          
        </div>
        <div className='card-1'>
          Total Balance <br/>
          $ {data.wallet.fundmanager_wallet_balance} 
        </div></center>
      </section>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form-1" onSubmit={(e) => handleSubmit(e, totalAmount1)} encType="multipart/form-data">
        <div className="custom-container">
        <div className="form-input mb-2 mt-5">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter User ID "
                onChange={handleChange}
                onBlur={handleReferral}
                required
              />
            </div>
          <div className="form-group mb-2">
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
            
          </div>
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Enter Your password (for verification):</label>
            <div className="form-input">
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Enter Your Password "
                onChange={handleChange}
                required
              />

            
              
            </div>
          </div>
          
          <button
          type="submit"
          className="btn theme-btn w-100"
          style={{ color: 'white' }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Transfurring...' : 'Transfer to Main Wallet'}
        </button></div>
      </form>
      {data.Tranfer_report.data && data.Tranfer_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.Tranfer_report.data.map((transfer, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={reward} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                <h5>
                    {transfer.receiver === data.user.email ? reasonMap1[transfer.reason] : reasonMap[transfer.reason]}
                </h5>

                  <h3 className={transfer.receiver === data.user.email ? 'success-color' : 'error-color'}>$ {transfer.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>{transfer.receiver === data.user.email ? transfer.sender : transfer.receiver }</h5>
                  <h5>{new Date(transfer.created_at).toLocaleDateString([], { day: '2-digit', month: '2-digit',year: '2-digit' })}</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.Tranfer_report.links && (
        <Pagination
          pageCount={data.Tranfer_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}

       <Footer />
        
      </div>
      </body>
  );
}

 


export default Transfer;